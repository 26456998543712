<template>
  <div class="content" v-loading.fullscreen.lock="loading">
    <base-header class="pb-6">
      <div class="row align-items-center py-2">
        <div class="col-lg-6 col-7"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" footer-classes="pt-1">
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-md-6">
                <h2 class="mb-0">My Badges</h2>
              </div>

              <div class="col-md-6 text-right">
                <template v-if="isCertficateChecked">
                  <a
                    :href="
                      baseUrl +
                        '/downloadAllCourseCertificate/single/' +
                        checked_certificate.join('_') +
                        '/' +
                        user_id
                    "
                    class="btn base-button custom-btn btn-default"
                    type="primary"
                    >Download<i class=" fa fa-download"></i
                  ></a>
                </template>
              </div>
            </div>
          </template>
          <div>
            <div class="row align-items-center justify-content-between mb-2">
              <div class=" col-md-12   col-12 text-right">
                <input type="checkbox" v-model="selectAll" @click="select" />
                Check All
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 mt-2">
                <div class="user-eltable">
                  <el-table
                    :data="queriedData"
                    row-key="id"
                    role="table"
                    class="certificatesGrid"
                    header-row-class-name="thead-light custom-thead-light"
                  >
                    <el-table-column min-width="50px" label="">
                      <template slot-scope="props">
                        <span>
                          <input
                            type="checkbox"
                            :value="props.row.id"
                            v-model="checked_certificate"
                            v-on:input="certificateCheckchange(props.row)"
                          />
                          <i class="form-icon"></i>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      min-width="250px"
                      align="left"
                      label="Course Name"
                    >
                      <template slot-scope="props">
                        <span>{{ props.row.badge_name }}</span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      min-width="200px"
                      align="left"
                      label="Action"
                    >
                      <template slot-scope="props">
                        <div class="row">
                          <div class="col-md-1">
                            <el-tooltip content="Preview" placement="top">
                              <a
                                style=""
                                target="_blank"
                                :href="
                                  baseUrl +
                                    '/downloadCourseBadge/preview/' +
                                    props.row.course_id +
                                    '/' +
                                    user_id +
                                    '/' +
                                    props.row.id
                                "
                                data-toggle="tooltip"
                                data-original-title="Preview"
                              >
                                <span>
                                  <i class="text-success fa fa-eye"></i> </span
                              ></a>
                            </el-tooltip>
                          </div>
                          <div class="col-md-1">
                            <el-tooltip content="Download" placement="top">
                              <a
                                :href="
                                  baseUrl +
                                    '/downloadCourseBadge/download/' +
                                    props.row.course_id +
                                    '/' +
                                    user_id +
                                    '/' +
                                    props.row.id
                                "
                                data-toggle="tooltip"
                                data-original-title="Download"
                                target="_blank"
                              >
                                <span>
                                  <i
                                    class="text-warning fa fa-download"
                                  ></i> </span
                              ></a>
                            </el-tooltip>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { Table, TableColumn, Select, Option } from "element-ui";
import clientPaginationMixin from "../Tables/PaginatedTables/clientPaginationMixin";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  mixins: [clientPaginationMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      loading: true,
      isCertficateChecked: false,
      baseUrl: this.$baseUrl,
      download_certificate: false,
      download_file_link: "",
      toolbarOptions: ["PdfExport"],
      allowPdfExport: true,
      uploadCertificateModal: false,
      hot_user: "",
      company_id: "",
      hot_token: "",
      user_id: "",
      config: "",
      certificate: {
        certificate_name: "",
        certificate_date: "",
        exp_date: "",
        certificate_file: "",
        course_id: ""
      },
      checked_certificate: [],
      tbl2_data: [],
      courses: [],
      tableData: [],
      expiredData: [],
      selectedRows: [],
      selected: [],
      selectAll: false,
      editor: "",
      course_ids: ""
    };
  },
  created: function() {
    if (localStorage.getItem("hot-token")) {
      this.hot_user = localStorage.getItem("hot-user");
      this.hot_token = localStorage.getItem("hot-token");
      this.company_id = localStorage.getItem("hot-company-id");

      if (this.hot_user === "company-admin") {
        this.user_id = localStorage.getItem("hot-admin-id");
      } else {
        this.user_id = localStorage.getItem("hot-user-id");
      }
    }
    this.loading = true;
    this.$http
      .post("employees/badges", {
        employee_id: this.user_id
      })
      .then(resp => {
        let badge_data = resp.data.data;

        for (let data of badge_data) {
          let obj = {
            id: data.id,
            course_id: data.course_id,
            badge_name: data.name
          };
          this.tableData.push(obj);
        }
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    formattedDate(data) {
      return moment(data).format("MM-DD-YYYY");
    },
    certificateCheckchange(row) {
      this.isCertficateChecked = true;
      if (this.checked_certificate.includes(row.id)) {
        this.checked_certificate.splice(
          this.checked_certificate.indexOf(row.id),
          1
        );
      } else {
        this.checked_certificate.push(row.id);
      }
      if (this.checked_certificate.length == 0) {
        this.isCertficateChecked = false;
      }
    },
    select() {
      this.checked_certificate = [];
      if (!this.selectAll) {
        this.isCertficateChecked = true;
        for (let i in this.tableData) {
          this.checked_certificate.push(this.tableData[i].id);
        }
        for (let i1 in this.tbl2_data) {
          this.checked_certificate.push(this.tbl2_data[i1].id);
        }
        for (let i1 in this.expiredData) {
          this.checked_certificate.push(this.expiredData[i1].id);
        }
      }
      if (this.checked_certificate.length === 0) {
        this.isCertficateChecked = false;
      }
    }
  }
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 0 0 1rem 0;
  }

  tr:nth-child(odd) {
    background: #ccc;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .certificatesGrid table.el-table__body td:nth-of-type(1):before {
    content: "Check";
  }
  .certificatesGrid table.el-table__body td:nth-of-type(2):before {
    content: "Course Name";
  }
  .certificatesGrid table.el-table__body td:nth-of-type(3):before {
    content: "Completion Date";
  }
  .certificatesGrid table.el-table__body td:nth-of-type(4):before {
    content: "Expirtion Date";
  }
  .certificatesGrid table.el-table__body td:nth-of-type(5):before {
    content: "Actions";
  }
}
</style>
